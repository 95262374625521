import { useEffect, useState } from 'react';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const Grid = (props) => {
  const campos = [
    { title: 'Id Usuário', order: 'k92_userid' },
    { title: 'Nome do Usuário', order: 'k92_nomusr' },
    { title: 'Matricula do User', order: 'k92_matric' },
    { title: 'Org do Usuário', order: 'k92_orgusr' },
    { title: 'Nível Usuário', order: 'k92_nivusr' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'k92_matric':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.k92_matric.localeCompare(b.k92_matric)
          )
        );
        break;
      case 'k92_nomusr':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.k92_nomusr.localeCompare(b.k92_nomusr)
          )
        );
        break;
      case 'k92_userid':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.k92_userid.localeCompare(b.k92_userid)
          )
        );
        break;
      case 'k92_orgusr':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.k92_orgusr?.localeCompare(b.k92_orgusr)
          )
        );
        break;
      case 'k92_nivusr':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.k92_nivusr?.localeCompare(b.k92_nivusr)
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
            <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              className="grid"
            >
              Visualizar
            </th>
            {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
            localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <th
                scope="col"
                style={{ fontSize: props.tamTexto, width: '80px' }}
                className="grid"
              >
                Editar
              </th>
            ) : null}
            {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <th
                scope="col"
                style={{ fontSize: props.tamTexto, width: '80px' }}
                className="grid"
              >
                Deletar
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr
              key={index}
              className={selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index)}
            >
              <td style={{ fontSize: props.tamSubTexto }}>{item.k92_userid}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.k92_nomusr}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.k92_matric}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.k92_orgusr}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.k92_nivusr}</td>
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    style={{ fontSize: props.tamSubTexto }}
                    onClick={() => {
                      props.setTitle('Visualizar');
                      props.setViewOrUpdate('view');
                      props.setFormData(item);
                      props.setActiveTab(3);
                    }}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </button>
                </div>
              </td>
              {localStorage.getItem('sessionNivelDeAcesso') === '16' ||
              localStorage.getItem('sessionNivelDeAcesso') === '22' ||
              localStorage.getItem('sessionNivelDeAcesso') === '50' ||
              localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                <td>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      style={{ fontSize: props.tamSubTexto }}
                      onClick={() => {
                        props.setTitle('Editar');
                        props.setInsertOrUpdate('update');
                        props.setViewOrUpdate('update');
                        props.setFormData(item);
                        props.setActiveTab(3);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                  </div>
                </td>
              ) : null}
              {localStorage.getItem('sessionNivelDeAcesso') === '22' ||
              localStorage.getItem('sessionNivelDeAcesso') === '50' ||
              localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                <td>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      style={{ fontSize: props.tamSubTexto }}
                      onClick={async () => {
                        const id = item.k92_userid;
                        await props.handleDelete(id);
                      }}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="8" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros:{' '}
                    {props.data.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
