export const CadGeral = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Chave (sigorg)</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cód. (codorg)</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Nome (nomorg)</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_sigorg"
              value={props.formData?.t04_sigorg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_codorg"
              value={props.formData?.t04_codorg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_nomorg"
              value={props.formData?.t04_nomorg || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Área</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Nível</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_area"
              value={props.formData?.t04_area || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_nivel"
              value={props.formData?.t04_nivel || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Nome Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">IP Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Porta Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_name"
              value={props.formData?.t04_name || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_host"
              value={props.formData?.t04_host || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_port"
              value={props.formData?.t04_port || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usuário Banco</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Senha Banco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_user"
              value={props.formData?.t04_user || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_password"
              value={props.formData?.t04_password || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Nome Banco Seg</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">IP Banco Seg</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Porta Banco Seg</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_name"
              value={props.formData?.t04_seg_name || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_host"
              value={props.formData?.t04_seg_host || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_port"
              value={props.formData?.t04_seg_port || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usuário Banco Seg</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Senha Banco Seg</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_user"
              value={props.formData?.t04_seg_user || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_seg_passw"
              value={props.formData?.t04_seg_passw || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Layout</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Telas Chk</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Criança Chk</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_layout"
              value={props.formData?.t04_layout || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_tela_chk"
              value={props.formData?.t04_tela_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_crianca_chk"
              value={props.formData?.t04_crianca_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Qtd. Adulto Chk</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Qtd. Dias Chk</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Assinatura Digital</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_qtd_adult_chk"
              value={props.formData?.t04_qtd_adult_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_qtd_dias_chk"
              value={props.formData?.t04_qtd_dias_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_ass_digital"
              value={props.formData?.t04_ass_digital || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Ambiente Obj.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Access Key Id</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Secret Access Key</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_ambient_obj"
              value={props.formData?.t04_ambient_obj || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_access_key_id"
              value={props.formData?.t04_access_key_id || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_secret_access_key"
              value={props.formData?.t04_secret_access_key || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Região</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Bucket</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Bucket Res.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_region"
              value={props.formData?.t04_region || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_bucket"
              value={props.formData?.t04_bucket || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_bucket_res"
              value={props.formData?.t04_bucket_res || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Bucket Chk.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Ambiente</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Porta App</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_bucket_chk"
              value={props.formData?.t04_bucket_chk || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_ambiente"
              value={props.formData?.t04_ambiente || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_porta_app"
              value={props.formData?.t04_porta_app || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Api Key</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Email Atd</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Email Com</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_api_key"
              value={props.formData?.t04_api_key || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_email_atd"
              value={props.formData?.t04_email_atd || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_email_com"
              value={props.formData?.t04_email_com || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Type</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Linguagem</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Usedef Endpoints</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_type"
              value={props.formData?.t04_type || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_language"
              value={props.formData?.t04_language || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="password"
              className="form-control form-control-sm border border-dark"
              name="t04_usedef_endpoints"
              value={props.formData?.t04_usedef_endpoints || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Storage Endpoint</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Meta 5S</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_storageendpoint"
              value={props.formData?.t04_storageendpoint || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="t04_meta_5s"
              value={props.formData?.t04_meta_5s || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
