import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { CLogin } from './components/CLogin';
import Header from './components/Header';
import Menu from './components/Menu';

import environment from './environment.json';
import { ApiMaster } from './services/apiService';

function App() {
  const [login, setLogin] = useState(false);
  const [dataEmpresa, setDataEmpresa] = useState([]);

  const [dataBanco, setDataBanco] = useState({});

  const [area, setArea] = useState();
  const [campoArea, setCampoArea] = useState(environment.Area);

  const area_verificar = (area) => {
    if (area === 'COMER') setArea('COMERCIO');
    else if (area === 'HOTEL') setArea('HOTEL');
    else if (area === 'RESTA') setArea('RESTAURANTE');
    else if (area === 'SERVI') setArea('SERVIÇO');
  };

  const abr_area = () => {
    if (area === 'COMERCIO') setCampoArea('COMER');
    else if (area === 'HOTEL') setCampoArea('HOTEL');
    else if (area === 'RESTAURANTE') setCampoArea('RESTA');
    else if (area === 'SERVIÇO') setCampoArea('SERVI');
  };

  const [bgMenu, setBgMenu] = useState('#ffffff');
  const [fontMenu, setFontMenu] = useState('#000000');

  const [changeState, setChangeState] = useState('');
  const [tamInput, setTamInput] = useState('1.10rem');
  const [tamTitulo, setTamTitulo] = useState('1.25rem');
  const [tamSubTitulo, setTamSubTitulo] = useState('1.12rem');
  const [tamTexto, setTamTexto] = useState('0.9rem');
  const [tamSubTexto, setTamSubTexto] = useState('0.7rem');
  const [isFuncCalled, setIsFuncCalled] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (changeState && !isFuncCalled) {
      Templates(changeState);
      setIsFuncCalled(true);
    }
  }, [changeState, isFuncCalled]);

  useEffect(() => {
    const savedConfigString = localStorage.getItem('config');
    if (savedConfigString) {
      const savedConfig = JSON.parse(savedConfigString);
      setBgMenu(savedConfig.bgMenu);
      setFontMenu(savedConfig.fontMenu);
    }
    setChangeState(environment.Tamanho_fonte);

    const handlePageUnload = () => {
      localStorage.clear();

      if (caches && caches.keys) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
      }
    };

    window.addEventListener('beforeunload', handlePageUnload);
    return () => {
      window.removeEventListener('beforeunload', handlePageUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem('sessionToken')) {
      setToken(localStorage.getItem('sessionToken'));
    }
  }, [login]);

  function Templates(tam) {
    setChangeState(tam);
    switch (tam) {
      case 'pequeno':
        setTamInput('1.10rem');
        setTamTitulo('1.25rem');
        setTamSubTitulo('1.12rem');
        setTamTexto('0.9rem');
        setTamSubTexto('0.7rem');
        break;
      case 'medio':
        setTamInput('1.5rem');
        setTamTitulo('1.5rem');
        setTamSubTitulo('1.25rem');
        setTamTexto('1.1rem');
        setTamSubTexto('1rem');
        break;
      case 'grande':
        setTamInput('2rem');
        setTamTitulo('2.5rem');
        setTamSubTitulo('1.75rem');
        setTamTexto('1.3rem');
        setTamSubTexto('1.1rem');
        break;
      default:
        break;
    }
  }

  const handleEmpresa = async (id) => {
    try {
      const response = await ApiMaster('get', `/empresa/${id}`);
      setDataBanco({
        user_info: response?.data?.empresa?.user_info,
        user_info_seg: response?.data?.empresa?.user_info_seg
      });
      setDataEmpresa(response?.data?.empresa?.user);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    area_verificar(dataEmpresa?.area);
    if (area) abr_area(area);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmpresa, area]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '100vh', width: '100vw' }}
              >
                ESCOLHER EMPRESA
              </div>
            }
          />
          <Route
            path="/:id"
            element={
              <>
                {!login ? (
                  <CLogin
                    dataBanco={dataBanco}
                    handleEmpresa={handleEmpresa}
                    setLogin={setLogin}
                  />
                ) : (
                  <>
                    <Header
                      dataEmpresa={dataEmpresa}
                      handleEmpresa={handleEmpresa}
                      login={login}
                      setLogin={setLogin}
                      bgMenu={bgMenu}
                      setBgMenu={setBgMenu}
                      fontMenu={fontMenu}
                      setFontMenu={setFontMenu}
                      tamInput={tamInput}
                      setTamInput={setTamInput}
                      tamTitulo={tamTitulo}
                      setTamTitulo={setTamTitulo}
                      tamSubTitulo={tamSubTitulo}
                      setTamSubTitulo={setTamSubTitulo}
                      tamTexto={tamTexto}
                      setTamTexto={setTamTexto}
                      tamSubTexto={tamSubTexto}
                      setTamSubTexto={setTamSubTexto}
                      changeState={changeState}
                      setChangeState={setChangeState}
                      dataBanco={dataBanco}
                      setDataBanco={setDataBanco}
                      campoArea={campoArea}
                      setCampoArea={setCampoArea}
                      area_verificar={area_verificar}
                      abr_area={abr_area}
                      area={area}
                      setArea={setArea}
                    />
                    <Menu
                      token={token}
                      bgMenu={bgMenu}
                      fontMenu={fontMenu}
                      login={login}
                      tamInput={tamInput}
                      tamTitulo={tamTitulo}
                      tamSubTitulo={tamSubTitulo}
                      tamTexto={tamTexto}
                      tamSubTexto={tamSubTexto}
                      dataBanco={dataBanco}
                      setDataBanco={setDataBanco}
                      dataEmpresa={dataEmpresa}
                    />
                  </>
                )}
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
