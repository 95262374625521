import { useState, useEffect } from 'react';

import i18n from '../services/i18n/i18n';
import Banco from './Banco';

function Config(props) {
  const [newBgNavbar, setNewBgNavbar] = useState(props.bgNavbar);
  const [newFontNavbar, setNewFontNavbar] = useState(props.fontNavbar);
  const [newBgMenu, setNewBgMenu] = useState(props.bgMenu);
  const [newFontMenu, setNewFontMenu] = useState(props.fontMenu);

  useEffect(() => {
    setNewBgNavbar(props.bgNavbar);
    setNewFontNavbar(props.fontNavbar);
    setNewBgMenu(props.bgMenu);
    setNewFontMenu(props.fontMenu);
  }, [props.bgNavbar, props.fontNavbar, props.bgMenu, props.fontMenu]);

  const [msgBanco, setMsgBanco] = useState(false);

  const config = {
    bgNavbar: newBgNavbar,
    fontNavbar: newFontNavbar,
    bgMenu: newBgMenu,
    fontMenu: newFontMenu
  };
  const configString = JSON.stringify(config);

  const saveChanges = () => {
    props.setBgNavbar(newBgNavbar);
    props.setFontNavbar(newFontNavbar);
    props.setBgMenu(newBgMenu);
    props.setFontMenu(newFontMenu);

    localStorage.setItem('config', configString);
  };

  const saveLanguage = (language) => {
    props.setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  const blcConfig = (props) => {
    return (
      <div className="form-group d-flex ms-3 mb-1 align-items-center border-bottom row">
        <label
          htmlFor={props.id}
          className="form-label col-md-3 col-sm-12"
          style={{ fontSize: props.tamTexto }}
        >
          {props.title}
        </label>
        <div className="col-4 mt-1">
          <input
            type="color"
            className="p-0 align-self-center form-control-color border border-dark"
            id={props.id}
            style={{ width: '100%' }}
            value={props.value1}
            onChange={(e) => props.value2(e.target.value)}
          />
        </div>
      </div>
    );
  };

  function Templates(tam) {
    props.setChangeState(tam);
    switch (tam) {
      case 'pequeno':
        props.setTamInput('1.5rem');
        props.setTamTitulo('1.25rem');
        props.setTamSubTitulo('1.12rem');
        props.setTamTexto('1.05rem');
        props.setTamSubTexto('0.9rem');
        break;
      case 'medio':
        props.setTamInput('1.75rem');
        props.setTamTitulo('1.5rem');
        props.setTamSubTitulo('1.25rem');
        props.setTamTexto('1.1rem');
        props.setTamSubTexto('1rem');
        break;
      case 'grande':
        props.setTamInput('2rem');
        props.setTamTitulo('2.5rem');
        props.setTamSubTitulo('1.75rem');
        props.setTamTexto('1.3rem');
        props.setTamSubTexto('1.1rem');
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="Config"
        tabIndex="-1"
        aria-labelledby="lblConfig"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title"
                id="lblConfig"
                style={{ fontSize: props.tamTitulo }}
              >
                Configuracao
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <h5
                className="border-bottom"
                style={{ fontSize: props.tamSubTitulo }}
              >
                Navbar
              </h5>

              {blcConfig({
                id: 'bgNavbar',
                title: 'Background',
                value1: newBgNavbar,
                value2: setNewBgNavbar,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              {blcConfig({
                id: 'fontNavbar',
                title: 'Fonte',
                value1: newFontNavbar,
                value2: setNewFontNavbar,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              <hr></hr>

              <h5
                className="border-bottom"
                style={{ fontSize: props.tamSubTitulo }}
              >
                Menu
              </h5>

              {blcConfig({
                id: 'bgMenu',
                title: 'Background',
                value1: newBgMenu,
                value2: setNewBgMenu,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              {blcConfig({
                id: 'fontMenu',
                title: 'Fonte',
                value1: newFontMenu,
                value2: setNewFontMenu,
                tamInput: props.tamInput,
                tamTexto: props.tamTexto
              })}

              <hr></hr>

              <h5
                className="border-bottom"
                style={{ fontSize: props.tamSubTitulo }}
              >
                Layout
              </h5>
              <div className="form-group d-flex ms-3 mb-1 align-items-center border-bottom row">
                <label
                  htmlFor="tamInput"
                  className="form-label col-md-3 col-sm-12"
                  style={{ fontSize: props.tamTexto }}
                >
                  Tamanho
                </label>
                <select
                  id="tamInput"
                  className="col ms-2 select border border-dark"
                  value={props.changeState}
                  style={{ height: props.tamInput, fontSize: props.tamTexto }}
                  onChange={(e) => Templates(e.target.value)}
                >
                  <option value="pequeno">Pequeno</option>
                  <option value="medio">Médio</option>
                  <option value="grande">Grande</option>
                </select>
              </div>
            </div>

            <div className="modal-footer d-flex justify-content-between">
              <div className="d-flex justify-content-start align-items-center">
                <button
                  type="button"
                  className={`btn button ${
                    props.selectedLanguage === 'ptBR' ? 'active' : ''
                  }`}
                  onClick={() => saveLanguage('ptBR')}
                >
                  <img
                    src="imagens/brasil.png"
                    alt="Logo Brasil"
                    className="img"
                  />
                </button>

                <button
                  type="button"
                  className={`btn button ${
                    props.selectedLanguage === 'en' ? 'active' : ''
                  }`}
                  onClick={() => saveLanguage('en')}
                >
                  <img src="imagens/usa.jpg" alt="Logo USA" className="img" />
                </button>

                <button
                  type="button"
                  className={`btn button ${
                    props.selectedLanguage === 'es' ? 'active' : ''
                  }`}
                  onClick={() => saveLanguage('es')}
                >
                  <img
                    src="imagens/espanha.png"
                    alt="Logo Espanha"
                    className="img"
                  />
                </button>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-target="#Banco"
                  data-bs-toggle="modal"
                  style={{ fontSize: props.tamTexto }}
                >
                  Ambiente
                </button>

                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  onClick={saveChanges}
                  data-bs-dismiss="modal"
                  style={{ fontSize: props.tamTexto }}
                >
                  Salvar
                </button>
              </div>

              {msgBanco && (
                <div
                  className="alert alert-danger mt-2 alert-dismissible col-12"
                  role="alert"
                >
                  <div style={{ fontSize: props.tamTexto }}>Não Logado</div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setMsgBanco(false)}
                    aria-label="Close"
                  ></button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Banco
        area={props.area}
        setArea={props.setArea}
        tamInput={props.tamInput}
        tamTexto={props.tamTexto}
        abr_area={props.abr_area}
        tamTitulo={props.tamTitulo}
        campoArea={props.campoArea}
        dataBanco={props.dataBanco}
        setCampoArea={props.setCampoArea}
        setDataBanco={props.setDataBanco}
        area_verificar={props.area_verificar}
      />
    </>
  );
}

export default Config;
