import { useEffect, useState } from 'react';

import { format } from 'date-fns';

const BlcTitle = (props) => {
  return (
    <th scope="col" className="grid">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: props.tamTexto }}>{props.title}</span>
        <div>
          <span onClick={() => props.handleSort(props.order, 'asc')}>
            <i className="fa-solid fa-arrow-up ms-2"></i>
          </span>
          <span
            className="ms-1"
            onClick={() => props.handleSort(props.order, 'desc')}
          >
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const Grid = (props) => {
  const campos = [
    { title: 'Emp', order: 'empresa' },
    { title: 'Loja', order: 'loja' },
    { title: 'Num Con', order: 'numcon' },
    { title: 'Tp Con', order: 'tipcon' },
    { title: 'C Cliente', order: 'codpes' },
    { title: 'Nome Cliente', order: 'nomcli' },
    { title: 'Dt Mov', order: 'damov' },
    { title: 'Dt Ven', order: 'datven' },
    { title: 'Dt Pag', order: 'datpag' },
    { title: 'Valor', order: 'valdps' }
  ];

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'empresa':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_codemp.localeCompare(b.cnt_codemp)
          )
        );
        break;
      case 'loja':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_loja.localeCompare(b.cnt_loja)
          )
        );
        break;
      case 'docpag':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_docpag.localeCompare(b.cnt_docpag)
          )
        );
        break;
      case 'numcon':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_numcon.localeCompare(b.cnt_numcon)
          )
        );
        break;
      case 'tipcon':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_tipcon.localeCompare(b.cnt_tipcon)
          )
        );
        break;
      case 'codpes':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              (a.cnt_codpes ?? '').localeCompare(b.cnt_codpes ?? '')
          )
        );
        break;
      case 'nomcli':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier *
              (a.cnt_nompes ?? '').localeCompare(b.cnt_nompes ?? '')
          )
        );
        break;
      case 'damov':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_damov.localeCompare(b.cnt_damov)
          )
        );
        break;
      case 'datven':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_datven.localeCompare(b.cnt_datven)
          )
        );
        break;
      case 'datpag':
        props.setData(
          [...props.data].sort(
            (a, b) => multiplier * a.cnt_datpag.localeCompare(b.cnt_datpag)
          )
        );
        break;
      case 'valdps':
        props.setData(
          [...props.data].sort(
            (a, b) =>
              multiplier * (parseFloat(a.cnt_valdps) - parseFloat(b.cnt_valdps))
          )
        );
        break;
      default:
        props.setData([...props.data]);
        break;
    }
  };

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(props.data.length / itemsPerPage));
    setCurrentPage(0);
    setSelectedRow(null);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={handleSort}
                tamTexto={props.tamTexto}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr
              key={index}
              className={selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index)}
            >
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_codemp}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_loja}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_numcon}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_tipcon}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.cnt_codpes}</td>
              <td className="grid" style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_nompes}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(item.cnt_damov.slice(0, 10)), 'dd/MM/yyyy')}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {format(new Date(item.cnt_datven.slice(0, 10)), 'dd/MM/yyyy')}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_datpag.slice(0, 10) !== '1899-12-30'
                  ? format(new Date(item.cnt_datpag.slice(0, 10)), 'dd/MM/yyyy')
                  : ''}
              </td>
              <td className="text-end" style={{ fontSize: props.tamSubTexto }}>
                {item.cnt_valdps.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="10" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="h-100 pagination pagination-sm">
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() => handleFirstPage()}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() => handlePageClick(currentPage - 1)}
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>
                  {displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === currentPage ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() => handlePageClick(currentPage + 1)}
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() => handleLastPage()}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Número de Registros:{' '}
                    {props.data.length.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
