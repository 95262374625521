import { ApiMasterEmp } from '../../../services/apiService';
import { Grid } from './Grid';

import Swal from 'sweetalert2';

export const CtrNavegacao = (props) => {
  const handleDelete = async (id) => {
    Swal.fire({
      title: `Deseja deletar o contexto: ${id}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiMasterEmp('delete', `/v1/auth/${id}`);
          if (response.status === 200 || response.status === 201) {
            Swal.fire({
              title: `Contexto : ${id} deletado`,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#d33',
              confirmButtonText: 'Fechar',
              allowOutsideClick: false
            });
            await props.handleReset();
            props.handleSearch();
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  return (
    <Grid
      handleDelete={handleDelete}
      data={props.data}
      setData={props.setData}
      tamTexto={props.tamTexto}
      tamSubTexto={props.tamSubTexto}
      setInsertOrUpdate={props.setInsertOrUpdate}
      setViewOrUpdate={props.setViewOrUpdate}
      dataEmpresa={props.dataEmpresa}
      setTitle={props.setTitle}
      setActiveTab={props.setActiveTab}
      setFormData={props.setFormData}
      dataFiltro={props.dataFiltro}
      dataFilter={props.dataFilter}
      setDataFilter={props.setDataFilter}
    />
  );
};
