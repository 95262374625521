import { useEffect, useState } from 'react';

import { ApiGet, ApiMasterEmp } from '../../services/apiService';
import { GridT04 } from './t04/GridT04';

import Swal from 'sweetalert2';

export const ContasDuplicadas = (props) => {
  const [dataT04, setDataT04] = useState([]);

  const handleContasDuplicadas = async (id) => {
    Swal.fire({
      title: `Deseja corrigir as contas duplicadas do contexto: "${id}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiGet(
            '/v1/suporte/contas/duplicadas',
            { contexto: id },
            localStorage.getItem('sessionToken')
          );
          if (response?.data.mensagem?.msg.slice(0, 3) !== '100') {
            alert(response?.data.mensagem?.msg);
            return;
          }

          Swal.fire({
            title: `Contas duplicadas corrigidas com sucesso`,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Fechar',
            allowOutsideClick: false
          });
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  const handleSearchEmpresa = async () => {
    try {
      const response = await ApiMasterEmp('get', '/v1/auth');
      const sortedData = response.data.sort((a, b) => {
        if (a.t04_sigorg < b.t04_sigorg) return -1;
        if (a.t04_sigorg > b.t04_sigorg) return 1;
        return 0;
      });

      setDataT04(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.isOpen === 'Menu-Contas-Duplicadas') {
      handleSearchEmpresa();
    }
  }, [props.isOpen]);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Contas-Duplicadas"
      tabIndex="-1"
      aria-labelledby="lblChavesDuplicadas"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblChavesDuplicadas"
              style={{ fontSize: props.tamTitulo }}
            >
              Correção de Contas Duplicadas
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          {props.isOpen === 'Menu-Contas-Duplicadas' && (
            <div className="modal-body">
              <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
                <p style={{ display: 'none' }}>Grid da T04</p>
              </div>

              <div className="tab-content">
                <div id="tab1">
                  <GridT04
                    setDataT04={setDataT04}
                    dataT04={dataT04}
                    handleContasDuplicadas={handleContasDuplicadas}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
