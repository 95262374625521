import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fLogin } from '../functions';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
  .object({
    user: yup.string().required('Campo Obrigatorio'),
    password: yup.string().required('Campo Obrigatorio')
  })
  .required();

export const CLogin = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [btnColor, setBtnColor] = useState('btn-danger');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await fLogin(data.user, data.password, props.dataBanco);

    if (response === 'Usuario nao existe') {
      setLoading(false);
      setBtnColor('btn-danger');
      alert('Usuario nao existe');
      return;
    }

    if (response) {
      setLoading(false);
      props.setLogin(true);
      setBtnColor('btn-secondary disabled');
    } else {
      setLoading(false);
      setBtnColor('btn-danger');
      alert('Error');
    }
  };

  useEffect(() => {
    props.handleEmpresa(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ width: '100%', height: '100vh' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="input_user">{t('Email/Usuario')}</label>
          <div>
            <input
              type="text"
              id="input_user"
              {...register('user')}
              className="form-control border border-dark"
            />
            <p className="text-danger">{errors.user?.message}</p>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="input_password">{t('Senha')}</label>
          <div>
            <input
              type="password"
              id="input_password"
              {...register('password')}
              className="form-control border border-dark"
            />
            <p className="text-danger">{errors.password?.message}</p>
          </div>
        </div>

        <div>
          <input
            className={`w-100 btn ${btnColor}`}
            type="submit"
            value={loading ? `${t('Enviando')}...` : t('Acessar')}
          />
        </div>
      </form>
    </div>
  );
};
