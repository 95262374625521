export const CadGeral = (props) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">N. Ocorrência</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tp. Ocorrência</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Cód. Ocorrência</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_numoco"
              value={props.formData?.ocr_numoco || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_tipoco"
              value={props.formData?.ocr_tipoco || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_codoco"
              value={props.formData?.ocr_codoco || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Desc. Ocorrência</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_desoco"
              value={props.formData?.ocr_desoco || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Empresa</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Loja</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Und. Adm.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_codemp"
              value={props.formData?.ocr_codemp || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_codloj"
              value={props.formData?.ocr_codloj || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_coduad"
              value={props.formData?.ocr_coduad || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-2">
            <span className="col-form-label">Cód. Pes.</span>
          </div>
          <div className="col-10">
            <span className="col-form-label">Nome</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_codpes"
              value={props.formData?.ocr_codpes || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_nome"
              value={props.formData?.ocr_nome || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Email</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_email"
              value={props.formData?.ocr_email || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Celular</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_telefone"
              value={`(${props.formData?.ocr_preftel || ''}) ${
                props.formData?.ocr_telefone || ''
              }`}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Ident. 1</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Ident. 2</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Ident. 3</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_ident1"
              value={props.formData?.ocr_ident1 || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_ident2"
              value={props.formData?.ocr_ident2 || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_ident3"
              value={props.formData?.ocr_ident3 || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Tp. Ident. 1</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tp. Ident. 2</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Tp. Ident. 3</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_tipident1"
              value={props.formData?.ocr_tipident1 || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_tipident2"
              value={props.formData?.ocr_tipident2 || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_tipident3"
              value={props.formData?.ocr_tipident3 || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Dt. Incl.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Hr. Incl.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Dt. Mov.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Hr. Mov.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ocr_datincl"
              value={props.formData?.ocr_datincl?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_horincl"
              value={props.formData?.ocr_horincl || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ocr_datmov"
              value={props.formData?.ocr_datmov?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_hormov"
              value={props.formData?.ocr_hormov || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Dt. Atz.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Hr. Atz.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Dt. Con.</span>
          </div>
          <div className="col-3">
            <span className="col-form-label">Hr. Con.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ocr_datatz"
              value={props.formData?.ocr_datatz?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_horatz"
              value={props.formData?.ocr_horatz || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              name="ocr_datcon"
              value={props.formData?.ocr_datcon?.slice(0, 10) || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_datcon"
              value={props.formData?.ocr_datcon || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">Usuário</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Usuário Cad.</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">Usuário Con.</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_usuario"
              value={props.formData?.ocr_usuario || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_usucad"
              value={props.formData?.ocr_usucad || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_usucon"
              value={props.formData?.ocr_usucon || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">ocr_grptip</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">ocr_desaut</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">ocr_valoco</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_grptip"
              value={props.formData?.ocr_grptip || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_desaut"
              value={props.formData?.ocr_desaut || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_valoco"
              value={props.formData?.ocr_valoco || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">ocr_undmed</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">ocr_empatd</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">ocr_assunto</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_undmed"
              value={props.formData?.ocr_undmed || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_empatd"
              value={props.formData?.ocr_empatd || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_assunto"
              value={props.formData?.ocr_assunto || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-4">
            <span className="col-form-label">ocr_codtta</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">ocr_conclusao</span>
          </div>
          <div className="col-4">
            <span className="col-form-label">ocr_codben</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_codtta"
              value={props.formData?.ocr_codtta || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_conclusao"
              value={props.formData?.ocr_conclusao || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
          <div className="col-4">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              name="ocr_codben"
              value={props.formData?.ocr_codben || ''}
              onChange={props.handleInput}
              disabled={props.viewOrUpdate === 'view' ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
