import { menu_condicao } from '../services/menu-condicao';

export const handleCard = () => {
  const nivelAdm = String(localStorage.getItem('sessionNivelDeAdministracao'));
  switchCard(nivelAdm);
  return menu_condicao;
};

const updateSituacao = (keys, situacao) => {
  menu_condicao.forEach((item) => {
    const key = Object.keys(item)[0];
    if (keys.includes(key)) {
      item[key].situacao = situacao;
    }
  });
};

function switchCard(nivelAdm) {
  switch (nivelAdm) {
    case '00':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '05':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '08':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '10':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '12':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '14':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '16':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '20':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '50':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;

    case '99':
      updateSituacao(
        [
          'empresa',
          'usuario',
          'k93',
          'ocorrencia',
          'contas_auditoria',
          'contas_duplicadas',
          'endpoint'
        ],
        'ATIVO'
      );
      break;
  }
}
