export const Search = (props) => {
  return (
    <>
      <div>
        <span className="col-form-label">Chave Org (Contexto)</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.sigorg || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                sigorg: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Cod Org</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.codorg || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                codorg: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Nome Org</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.nomorg || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                nomorg: e.target.value
              })
            }
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Ambiente</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.ambiente || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                ambiente: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="DEV">DEV</option>
            <option value="HOMOL">HOMOL</option>
            <option value="PROD">PROD</option>
          </select>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={props.handleFiltro}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
