import { useState } from 'react';

import { CadGeral } from './CadGeral';

export const Cadastro = (props) => {
  const [activeTab] = useState(1);

  const handleInput = (event) => {
    const { name, value } = event.target;
    props.setFormData({ ...props.formData, [name]: value });
  };

  return (
    <>
      <div className="nav nav-tabs">
        <p style={{ display: 'none' }}>Geral</p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
          id="tabCadastro1"
        >
          <CadGeral
            handleInput={handleInput}
            formData={props.formData}
            setFormData={props.setFormData}
            viewOrUpdate={props.viewOrUpdate}
          />
        </div>
      </div>
    </>
  );
};
