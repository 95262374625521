function Banco(props) {
  const saveChanges = () => {
    props.area_verificar();
  };

  function atualizarDataBanco(campos, newValue) {
    props.setDataBanco({ ...props.dataBanco, [campos]: newValue });
  }

  return (
    <>
      <div
        className="modal fade"
        id="Banco"
        tabIndex="-1"
        aria-labelledby="lblBanco"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title"
                id="lblBanco"
                style={{ fontSize: props.tamTitulo }}
              >
                Ambiente
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div>
                <h5 className="border-bottom">SIE</h5>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="bancoSie"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Servidor
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="bancoSie"
                    value={props.dataBanco.sie_servidor || ''}
                    onChange={(e) =>
                      atualizarDataBanco('sie_servidor', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="portaSie"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Porta
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="portaSie"
                    value={props.dataBanco.sie_porta || ''}
                    onChange={(e) =>
                      atualizarDataBanco('sie_porta', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="nomeSie"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="nomeSie"
                    value={props.dataBanco.sie_nome || ''}
                    onChange={(e) =>
                      atualizarDataBanco('sie_nome', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <hr className="mb-3"></hr>

                <h5 className="pb-2 border-bottom">SEG</h5>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="bancoSeg"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Servidor
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="bancoSeg"
                    value={props.dataBanco.seg_servidor || ''}
                    onChange={(e) =>
                      atualizarDataBanco('seg_servidor', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="portaSeg"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Porta
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="portaSeg"
                    value={props.dataBanco.seg_porta || ''}
                    onChange={(e) =>
                      atualizarDataBanco('seg_porta', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="nomeSeg"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="nomeSeg"
                    value={props.dataBanco.seg_nome || ''}
                    onChange={(e) =>
                      atualizarDataBanco('seg_nome', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <hr className="mb-3"></hr>

                <h5 className="pb-2 border-bottom">Conexão</h5>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="loginSie"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Login
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="loginSie"
                    value={props.dataBanco.login || ''}
                    onChange={(e) =>
                      atualizarDataBanco('login', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="senhaSie"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Senha
                  </label>
                  <input
                    type="text"
                    className="form-control border border-dark"
                    id="senhaSie"
                    value={props.dataBanco.senha || ''}
                    onChange={(e) =>
                      atualizarDataBanco('senha', e.target.value)
                    }
                    style={{ height: props.tamInput }}
                  />
                </div>

                <hr className="mb-3"></hr>

                <h5 className="pb-2 border-bottom">Card</h5>

                <div className="form-group d-flex ms-3 align-items-baseline">
                  <label
                    htmlFor="area"
                    className="form-label"
                    style={{ width: '120px', fontSize: props.tamTexto }}
                  >
                    Area
                  </label>
                  <select
                    className="form-select form-control border border-dark border-opacity-75"
                    value={props.campoArea}
                    onChange={(e) => props.setCampoArea(e.target.value)}
                    style={{ height: props.tamInput, padding: 0 }}
                  >
                    <option value="COMER">COMER</option>
                    <option value="HOTEL">HOTEL</option>
                    <option value="RESTA">RESTA</option>
                    <option value="SERVI">SERVI</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                style={{ fontSize: props.tamTexto }}
                onClick={saveChanges}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banco;
