export let menu_condicao = [
  {
    empresa: {
      coluna: '1',
      linha: '1',
      nome: 'Empresas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Empresa'
    }
  },
  {
    usuario: {
      coluna: '2',
      linha: '1',
      nome: 'Usuários (kusr92)',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Usuarios'
    }
  },
  {
    k93: {
      coluna: '2',
      linha: '2',
      nome: 'Usuário/Sistema (kusr93)',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-K93'
    }
  },
  {
    ocorrencia: {
      coluna: '1',
      linha: '2',
      nome: 'Ocorrências',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Ocorrencias'
    }
  },
  {
    contas_auditoria: {
      coluna: '3',
      linha: '1',
      nome: 'Auditoria de Contas Duplicadas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Contas-Auditoria'
    }
  },
  {
    contas_duplicadas: {
      coluna: '3',
      linha: '2',
      nome: 'Correção de Contas Duplicadas',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Contas-Duplicadas'
    }
  },
  {
    endpoint: {
      coluna: '3',
      linha: '3',
      nome: 'Endpoints',
      situacao: 'INATIVO',
      status: 'PROD',
      chave: 'Menu-Endpoints'
    }
  }
];

menu_condicao.sort((a, b) => {
  const keyA = Object.keys(a)[0];
  const keyB = Object.keys(b)[0];
  return a[keyA].linha - b[keyB].linha;
});
