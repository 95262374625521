import { apiSalt, apiData } from './api';

async function ApiGet(cam, body, token) {
  try {
    const response = await apiSalt.get(cam, {
      params: body,
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiGetConsulta(cam, token) {
  try {
    const response = await apiSalt.get(cam, {
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiDelete(url, body) {
  try {
    const response = await apiSalt.delete(url, {
      data: body,
      headers: { Authorization: localStorage.getItem('sessionToken') }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPost(
  cam,
  tipo,
  token,
  sie_servidor,
  sie_porta,
  sie_nome,
  login,
  senha
) {
  try {
    const response = await apiSalt.post(
      cam,
      {
        tipo: tipo,
        sie_servidor: sie_servidor,
        sie_porta: sie_porta,
        sie_nome: sie_nome,
        login: login,
        senha: senha
      },
      { headers: { Authorization: token } }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function NewApiPost(url, body, token) {
  try {
    const response = await apiSalt.post(url, body, {
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPostLogin(cam, name, password, user_info, user_info_seg) {
  try {
    const response = await apiSalt.post(cam, {
      name: name,
      password: password,
      user_info: user_info,
      user_info_seg: user_info_seg
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPut(cam, body, token) {
  try {
    const response = await apiSalt.put(cam, body, {
      headers: { Authorization: token }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiGetEmp(cam) {
  try {
    const response = await apiSalt.get(cam);
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiMasterEmp(method, url, body = {}) {
  try {
    const response = await apiData[method](url, body);
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiPostData(cam, body) {
  try {
    const response = await apiSalt.post(cam, body, {
      headers: { Authorization: localStorage.getItem('sessionToken') }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiMasterData(method, url, body = {}) {
  try {
    const response = await apiSalt[method](url, body);
    return response;
  } catch (err) {
    console.log(err);
  }
}

async function ApiMaster(method, url, body = {}) {
  try {
    const response = await apiSalt[method](url, body);
    return response;
  } catch (err) {
    console.log(err);
  }
}

export {
  ApiGet,
  ApiGetConsulta,
  ApiPost,
  ApiPut,
  ApiGetEmp,
  ApiMasterEmp,
  ApiPostData,
  ApiMasterData,
  ApiPostLogin,
  ApiMaster,
  ApiDelete,
  NewApiPost
};
